<template>
  <Day day="01.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center">ELÉGIA A VETKŐZÉSRŐL</h1>
      <p class="align-center">
        Jöjj, hölgyem, jöjj és vetkőzz le velem,<br />
        vágy kínoz, mikor nem szeretkezem.<br />
        S mint harcos, ha ellenségre talál:<br />
        lándzsám megfájdul, mert nem döf, csak áll. […]<br /><br />

        <span class="italic">(John Donne, Faludy fordításában)</span>
      </p>
<br />
<hr />
<br />
      <p class="align-center">Egy, két pillanat és eltelik... <br />
De miért ne legyen érdekes?!<br />
Minden egyes napkeltével egy pikánsan fűszeres,<br />
édes, cuki, szerelmes, <br />
szösszenettel leplek meg. ❤</p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day01",
  components: {
    Day,
  },
};
</script>
  

<style lang="scss">
.page-content{
  
}
.italic{
  font-style: italic;
}
</style>