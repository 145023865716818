<template>
  <Day day="14.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Daily QA</h1>

      <h2 class="px-5">A kapcsolatunk melyik részére vagy a legbüszkébb? Mit csinálunk nagyon jól együtt?</h2>
      <p class="px-5 mt-5 strong">Az én válaszom:</p>
      <p class="px-5 mb-5 hover">
        A nyilvánvaló szexen kívül? :) <br />
        Szerintem egyre jobban kiegészítjük egymást. Egyre könnyebb a másiktól segítséget, ötletet, vagy támogatást kérnünk és így jobban összefonódunk és kevesebbet stresszelünk.
      </p>

      <h2 class="px-5 mt-5">Mi a kedvenc emléked rólunk mint párról?</h2>
      <p class="px-5 mt-5 strong">Az én válaszom:</p>
      <p class="px-5 mb-5 hover">
        Ez nehezebb, mint elsőre gondoltam. <br />
        Van sok olyan emlék, ami azonnal beindít (parkolóban huncutkodás, asztalon dugás a szálláson...), van sok, amitől mosolygok (ilyenek a nagyon nevetős szituk), és vannak, amikre egyszerűen jó visszaemlékezni (nem párként volt, de a buszmegállós talik, vagy rapid mátrai kiruccanás). És van egy csomó, ami minden kategóriába, vagy egyikbe sem tartozik... :D
      </p>

      <h2 class="px-5 mt-5">Írd le azt a fizikai érintést, amely a legjobban közvetíti számodra, hogy a másik szeret?</h2>
      <p class="px-5 mt-5 strong">Az én válaszom:</p>
      <p class="px-5 hover">
        Amikor jó szorosan odabújsz hozzám, buksid a mellkasomon, a kezed hozzáér a bőrömhöz és gyengéden simogatsz (ha ilyenkor jön egy csók is, akkor szokott olyan kemény lenni a farkam, amit annyira imádsz)
      </p>
      
      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day14",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>