<template>
  <Day day="18.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Helyzetre való tekintettel</h1>

      <p class="px-5 py-5">
        "Olyan hideg van" - Gondolod magadban, <br />
        mikor a takarót nyakig húzod az ágyadban. <br />
        Becsukod szemeid, képzeleted szárnyal, <br />
        valami hozzád ér finoman, szájjal.<br />
        Puha csók, mely tele van romantikával,<br />
        elárasztja testedet forró erotikával.<br />
        Ekkor a semmiből, a hasadra váratlan,<br />
        egy kéz csúszik és simogat nyájasan.<br />
        Így ringat álomba ez a kényeztetés,<br />
        holnap reggel úgy ébredsz, mint aki mindenre kész! ♥
      </p>

      <p class="px-5 py-5">
        <v-img
            style="margin: 0 auto"
            max-width="300"
            src="../../assets/teasing.gif"
        ></v-img>
      </p>


      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day18",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>