<template>
  <Day day="06.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Santa is cumming soon</h1>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> 1 kép többet mond ezer szónál? </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img
              style="margin: 0 auto"
              max-width="300"
              src="../../assets/nullaot.jpg"
            ></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day05",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("santa");
  },
  destroyed() {
    document.body.classList.remove("santa");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>