<template>
  <Day day="04.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Csaló nap</h1>
      
      <p class="align-center px-5">
        <strong>Ez a mai egy csaló nap.</strong> <br />
        Mármint fitnesz értelemben csaló. Ma ugye megyünk Borka <span style="text-decoration: line-through;">szülinapra</span> névnapra szüleimhez, ahol valószínűleg <i>megtömnek, mint egy libát</i>, de nem szeretném, ha felkészületlenül érne az a kaja sokk, úgyhogy bemelegítünk rá egy fincsi és kényeztető <i>ágyban reggelivel</i> ;)

      </p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day01",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add('breakfast')
  },
  destroyed() {
    document.body.classList.remove('breakfast')
  },
};
</script>
  

<style lang="scss">

.italic{
  font-style: italic;
}
p{
  font-size: 1.3em;
  line-height: 1.8;
}
</style>