<template>
  <Day day="15.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">A hármas</h1>
      <h2></h2>

      <p class="px-5 py-5">
        Úgy lesz, ahogy mondtam már,<br />
        Keményebben! mondtad már.<br />
        Kérésed halló fülekre talált,<br />
        Megmasszlak, mára ennyi dukál.
      </p>
      <hr />
      <p class="px-5 py-5">
        Hogyha elmúlt hátfájásod,<br />
        kalandozok. Ide-oda,<br />
        szívedtől a tomporodba,<br />
        vagy a lucskos puncusodba.
      </p>
      <hr />
      <p class="px-5 py-5">
        "ÁÁÁ ÁÁÁÁ" - ne tátogjál,<br />
        mert még valami beleszáll.<br />
        És ha az oda betalál,<br />
        jó sokáig ki se száll.
      </p>
      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day15",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>