<template>
  <Day day="03.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Tejfehér köd</h1>
      <p class="align-center px-5">
        Nem értem, hogy lehet ekkora köd, mindent homályosan látok. Még az utcán közeledő arcokat sem tudom rendesen kivenni. És most még ez a fura érzés is megjelent a hasamnál, mintha nyomná valami. Mindegy, nem fordulhatok most vissza, mennem kell tovább. Mégis lassítanom kell. A köd miatt nyirkos lett a bőröm és a légzés is egyre nehezebben megy. Mi történik velem?? Jujujujujujj, most meg szédülök. MI A FRANC EZ?? Földrengés van?! Nem! FORGÓSZÉL?!?! SEGÍTSÉÉÉÉÉG....
      </p>
      <p class="align-center px-5">
        Pillanatok alatt, hatalmas magasságokba repített, majd egy pillanatra megálltam a levegőben. Olyan gyönyörű és nyugodt volt ott fent minden, hogy szinte el is felejtetkeztem arról, ami a következő másodpercekben várt rám. Ám ekkor zuhanni kezdtem, az adrenalin elárasztotta a testem. Ahogy közeledett a föld, egyre inkább felerősödött az a fura érzés is bennem és szétterjedt mindenhová. Minden egyre nagyobb, mindjárt itt a becsapódás.........
      </p>
      <p class="align-center px-5">
        AHHHHHHHHH!!!!!
      </p>
      <p class="align-center px-5">
        A becsapódás fájdalmai helyett, egy felfoghatatlanul csodás érzés várt rám. Még ugyan a szemem nem tudom teljesen kinyitni, mégis tisztábban látom az arcod, mint néhány másodperccel ezelőtt bárkiét. A szemembe nézel, miközben a betonkemény farkam csak pumpálja és pumpálja a szádba a korán ébredés jutalmát. Egy huncut kis mosoly kíséretében egy búcsúpuszit adsz "neki" és jó szorosan hozzám bújsz.
      </p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day01",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add('mist')
  },
  destroyed() {
    document.body.classList.remove('mist')
  },
};
</script>
  

<style lang="scss">

.italic{
  font-style: italic;
}
p{
  font-size: 1.3em;
  line-height: 1.8;
}
</style>