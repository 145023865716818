import Vue from 'vue'
import VueRouter from 'vue-router'
import CalendarPage from '@/views/CalendarPage.vue'
import NotFound from '@/views/NotFound.vue'
import Day01 from '@/views/days/Day01.vue'
import Day02 from '@/views/days/Day02.vue'
import Day03 from '@/views/days/Day03.vue'
import Day04 from '@/views/days/Day04.vue'
import Day05 from '@/views/days/Day05.vue'
import Day06 from '@/views/days/Day06.vue'
import Day07 from '@/views/days/Day07.vue'
import Day08 from '@/views/days/Day08.vue'
import Day09 from '@/views/days/Day09.vue'
import Day10 from '@/views/days/Day10.vue'
import Day11 from '@/views/days/Day11.vue'
import Day12 from '@/views/days/Day12.vue'
import Day13 from '@/views/days/Day13.vue'
import Day14 from '@/views/days/Day14.vue'
import Day15 from '@/views/days/Day15.vue'
import Day16 from '@/views/days/Day16.vue'
import Day17 from '@/views/days/Day17.vue'
import Day18 from '@/views/days/Day18.vue'
import Day19 from '@/views/days/Day19.vue'
import Day20 from '@/views/days/Day20.vue'
import Day21 from '@/views/days/Day21.vue'
import Day22 from '@/views/days/Day22.vue'
import Day23 from '@/views/days/Day23.vue'
import Day24 from '@/views/days/Day24.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: CalendarPage
  },
  { path: '/days/day1', name: 'Day1', component: Day01 },
  { path: '/days/day2', name: 'Day2', component: Day02 },
  { path: '/days/day3', name: 'Day3', component: Day03 },
  { path: '/days/day4', name: 'Day4', component: Day04 },
  { path: '/days/day5', name: 'Day5', component: Day05 },
  { path: '/days/day6', name: 'Day6', component: Day06 },
  { path: '/days/day7', name: 'Day7', component: Day07 },
  { path: '/days/day8', name: 'Day8', component: Day08 },
  { path: '/days/day9', name: 'Day9', component: Day09 },
  { path: '/days/day10', name: 'Day10', component: Day10 },
  { path: '/days/day11', name: 'Day11', component: Day11 },
  { path: '/days/day12', name: 'Day12', component: Day12 },
  { path: '/days/day13', name: 'Day13', component: Day13 },
  { path: '/days/day14', name: 'Day14', component: Day14 },
  { path: '/days/day15', name: 'Day15', component: Day15 },
  { path: '/days/day16', name: 'Day16', component: Day16 },
  { path: '/days/day17', name: 'Day17', component: Day17 },
  { path: '/days/day18', name: 'Day18', component: Day18 },
  { path: '/days/day19', name: 'Day19', component: Day19 },
  { path: '/days/day20', name: 'Day20', component: Day20 },
  { path: '/days/day21', name: 'Day21', component: Day21 },
  { path: '/days/day22', name: 'Day22', component: Day22 },
  { path: '/days/day23', name: 'Day23', component: Day23 },
  { path: '/days/day24', name: 'Day24', component: Day24 },
  { path: '*', name: 'NotFound', component: NotFound },

]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
