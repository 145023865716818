<template>
  <Day day="12.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Az igazi?</h1>

      <p class="px-5">
        Elég bátor vagy ahhoz, hogy felfedd az igazi arcom?
      </p>

      <div @mouseover="hover = true" @mouseleave="hover = false">
        <img src="../../assets/12a.jpg" alt="" v-show="!hover" />
        <img src="../../assets/12.jpg" alt="" v-show="hover" />
      </div>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day12",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("northern-light");
  },
  destroyed() {
    document.body.classList.remove("northern-light");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>