<template>
  <Day day="16.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Mi fér még a puttonyba?</h1>

      <p class="px-5 py-5">
        December tizenhat, <br />
        seggedre nagyot csap!<br />
        Tegnapit folytatva,<br />
        Popsidba kukit kapsz!
      </p>

      <p class="px-5 py-5">
        <v-img
            style="margin: 0 auto"
            max-width="300"
            src="../../assets/spanking.webp"
        ></v-img>
      </p>


      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day16",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>