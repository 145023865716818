<template>
  <Day day="13.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Főpróba</h1>

      <p class="px-5">
        Kinyílik az ajtó, sikerül végre bejutnod az üres lakásba. Az előszobában egy laza mozdulattal megválsz a cipődtől, a táskád leteszed és a másik kezedben lévő papírtáskával a kanapé irányába indulsz. Lehuppansz és élvezed, hogy a lakásban még egy kicsit hűvösebb van, mint a napon. Gyorsan leveszed a felsőd és a rövidnadrágod és csukott szemmel várod, hogy lehűljön egy kicsit a bőröd.
      </p>

      <p class="px-5">
        Hirtelen kipattan a szemed és vigyorogva beletúrsz az ajándékszatyorba, majd egy kis matatás után, kiveszel egy aprócska textilt. Elmosolyodsz, de ez most egy huncut mosoly. Olyan huncut, mint amikor valaki elképzeli, hogy mennyire szexi lesz este és milyen vad vágyakat fog gerjeszteni.
      </p>

      <p class="px-5">
        Gyorsan kikapcsolod a melltartódat és ledobod az ágyra. Melleid végre szabadok, bimbóid keményen meredeznek bele a világba és vágyakozva nyújtóznak, hátha valaki megsimogatja, vagy gyengéden megnyalogatja őket. Közben beakasztod az ujjaidat a bugyidba és szexi tánc és kígyómozgás kíséretében letolod a bokádra, hogy könnyedén kiléphess belőle. Megfordulsz, a kikapcsolt TV kijelzőjének tükröződésében megnézed a popsid, rácsapsz egyet, majd még mindig vigyorogva felkapod a kanapéról az új szerzeményt. Belelépsz az egyik lábaddal, majd a másikkal is, lassan felhúzod, kezd körvonalazódni a ruhadarab funkciója. A pucérságod eltakarására egy elképzelhetetlenül dögös body-t próbálsz meg bevetni, de valójában nem sokat takar. A finom anyag követi a elképesztő tested vonalát, ámulatba ejtő játékot játszik, ahogy helyenként feltűnik, majd újra elveszik. Neked is nagyon tetszik a látvány, a szádat harapdálod, az agyad már máshol jár. Visszahelyezkedsz a kanapéra, a body anyagán finoman végigfuttatod az ujjaid és finoman megmarkolod a melled, majd a másik kezed elindul a body aljához, hogy néhány bemelegítő simogatás után rád vesse magát egy KURVA NAGY MUTÁNS LÉGY!
      </p>

      <p class="px-5">
        ... picsába! Pont most kell rászállnod a távcsőre? Na tűnés innen, nem érek most rá. El kell élveznem erre a kis cafkára!
      </p>

    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day13",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>