<template>
  <Day day="08.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">
        MC Strumpi és DJ Pepitapocok bemutatja:
      </h1>

      <audio controls>
        <source src="../../assets/mc-dj-producer-strumpi.mp3" type="audio/mp3" />
        <source src="../../assets/mc-dj-producer-strumpi.wav" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day08",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("party");
  },
  destroyed() {
    document.body.classList.remove("party");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>