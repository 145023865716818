<template>
  <Day day="17.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Elkésve</h1>

      <p class="px-5 py-5">
        <span style="color: #009688">SZ</span>ánom bánom, nagyon sajnálom,<br />
        <span style="color: #009688">E</span>zt az ablakot késve aktiválom.<br />
        <span style="color: #009688">R</span>emélem, hogy nem szegi kedved,<br />
        <span style="color: #009688">E</span>zután is elérhetem még a tested.<br /><br />
        <span style="color: #009688">T</span>áncra perdülök, ha rázod a segged<br />
        <span style="color: #009688">L</span>etérdelve csak annyit kell tenned,<br />
        <span style="color: #009688">E</span>ksztázisba esve, sebesen nyelned,<br />
        <span style="color: #009688">K</span>örbe-körbe járatni rajtam a nyelved.
      </p>

      <p class="px-5 py-5">
        <v-img
            style="margin: 0 auto"
            max-width="300"
            src="../../assets/sexy-excited.gif"
        ></v-img>
      </p>
      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day17",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>