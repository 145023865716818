<template>
  <Day day="07.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Kifogytam a szóból, ezt hoztam az ivóból</h1>

      <!-- <p>Na mit hoztam az ivóból?</p> -->
      <p class="px-5">
        <strong>F</strong>orró lesz a hangulat, <br />
        <strong>O</strong>rrod is majd jót mulat, <br />
        <strong>R</strong>ottyan egyet, s még párat, <br />
        <strong>R</strong>ögtön tovaszáll a bánat. <br />
        <strong>A</strong>rról híres a kis vörös, <br />
        <strong>L</strong>efetyeled rögtön fröcsög. <br />
        <strong>T</strong>orkodon csak lefolyik, <br />
        <strong>B</strong>ár a véred változik, <br />
        <strong>O</strong>stromolja lábad közét, <br />
        <strong>R</strong>ád célzom a puskám csövét.
      </p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day05",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("bor");
  },
  destroyed() {
    document.body.classList.remove("bor");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>