<template>
  <Day day="02.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Közös téli bakancslista</h1>
      <p class="align-center px-5">
        Szeretnék elmenni szánkózni, hógolyózni, nagy hóban cicis ké... kirándulni, 
        összebújva filmezni, pléd alatt tapizni, fejedet lenyomni, kádban chillelni, 
        pucéran társasozni, éjjelig táncolni, ágyban reggelizni, mocskosul szexelni, 
        sokat nevetni, melletted elaludni és melletted ébredni, forralt bort szürcsölni, 
        hóesésben csókolózni, közösen sütit sütni, reggeli sátrat állítani és Veled lebontatni, 
        rengeteg közös programot csinálni és arcodon sok mosolyt látni. 
        Szeretném ezt a listát a Tieddel egybegyúrni és közösen bővíteni.

      </p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day01",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add('northern-light')
  },
  destroyed() {
    document.body.classList.remove('northern-light')
  },
};
</script>
  

<style lang="scss">

.italic{
  font-style: italic;
}
p{
  font-size: 1.3em;
  line-height: 1.8;
}
</style>