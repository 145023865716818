<template>
  <Day day="21.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Közös halmaz?</h1>

      <p class="px-5 py-5">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/1WwoLiOyFyQMpqEHCALDEg?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      </p>

      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day18",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>