<template>
  <Day day="05.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Havas dombok</h1>

      <p class="px-5">Itt is van már december öt, <br />
hirtelen csak úgy szembejött.<br />
A hó még nem sajnos nem esik,<br />
a hideg meg jól már nem esik.</p>

<p class="px-5">
  Egy dolog van csak mi melegít,<br />
ha popsid formája felsejlik.<br />
Távol vagy, ma még csak gondolatban,<br />
de holnap már itt lesz a markomban.
</p>

<p class="px-5">
  Vulkán, láva, földrengés,<br />
másoknak ez katasztrófa,<br />
de a tested vad tengerén<br />
ez az orgazmusra szinonima.
</p>

<p class="px-5">
  Előttem szép havas dombok, <br />
hol a domb a segged,<br />
s a havas foltok, mik borítják,<br />
belőlem tör fel rendre.
</p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day05",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("peach");
  },
  destroyed() {
    document.body.classList.remove("peach");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>