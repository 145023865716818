<template>
  <Day day="11.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">A tegnapin felbuzdulva</h1>

      <p class="px-5">
        Szóval tegnap jó volt látni, hogy mennyire élvezted azt a célzott
        masszázst és szeretném, ha ez sűrűbben szerepelne az étlapon.
      </p>
      <p class="px-5">
        <v-img
          style="margin: 0 auto"
          max-width="700"
          src="../../assets/coupon-pussy-massage.png"
        ></v-img>
      </p>

      <p class="px-5 pt-5">
        ui: ez nem azt jelenti, hogy a masztizást nem látnám szívesen többször ;-P 
      </p>
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day11",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>