<template>
  <Day day="09.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">
        NégySOROS
      </h1>

      <p class="px-5">
        Ma elfoglalt vagy, úgyhogy csalok.<br />
        A naptárban csak ennyit hagyok:<br />
        Jó bulit és zabálást,<br />
        előzd meg a fejfájást.
      </p>

    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day09",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("");
  },
  destroyed() {
    document.body.classList.remove("");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>