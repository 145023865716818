<template>
  <Day day="19.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Egy kis Benedek Elek ;-)</h1>

      <p class="px-5 py-5">
        <audio controls>
        <source src="../../assets/edes-alom.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      </p>

      
    </div>
  </Day>
</template>
  
  <script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day18",
  components: {
    Day,
  },
  data() {
    return {
      hover: false,
    };
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}
p {
  font-size: 1.3em;
  line-height: 1.8;
}
.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;
  &:hover {
    filter: blur(0);
  }
}
</style>